import React, { useEffect, useState } from "react";
import moment from "moment";
import { Avatar, Modal, Pagination, Spin, message } from "antd";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from "xlsx";
import CategoryForm from "./Categoryform";

const XLSTrackingTable = ({ load, data }) => {
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of rows per page
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
    garageId: "", // Add garageId to filter criteria
  });

  // Set filtered garage list when data changes
  useEffect(() => {
    setFilteredGarageList(data); // Assuming 'data' is the array provided
  }, [data]);

  // Apply filtering based on criteria
  const applyFilters = () => {
    const filteredList = data.filter((item) => {
      const fromDateMatch =
        !filterCriteria.fromDate ||
        moment(item.lastUpdated).isSameOrAfter(filterCriteria.fromDate);
      const toDateMatch =
        !filterCriteria.toDate ||
        moment(item.lastUpdated).isSameOrBefore(filterCriteria.toDate);
      const statusMatch =
        !filterCriteria.status ||
        (item.status ? "active" : "inactive") === filterCriteria.status;
      const searchMatch =
        !filterCriteria.search ||
        item.garageName
          .toLowerCase()
          .includes(filterCriteria.search.toLowerCase());
      const garageIdMatch =
        !filterCriteria.garageId || item.garageId === filterCriteria.garageId; // Check garageId match

      return (
        fromDateMatch &&
        toDateMatch &&
        statusMatch &&
        searchMatch &&
        garageIdMatch
      );
    });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria((prev) => ({
      ...prev,
      ...values,
    }));
    applyFilters(); // Apply filters when criteria changes
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRows = filteredGarageList.slice(startIndex, endIndex);

  const onChange = (page) => {
    setCurrentPage(page);
  };

  const handleExportExcel = () => {
    const formattedData = filteredGarageList.map((item, index) => {
      const formattedDate = moment(item.lastUpdated).format("DD-MM-YYYY");
      return [
        startIndex + index + 1,
        formattedDate || "-",
        item.garageName || "-",
        item.totalXlsDownloads || "-",
        item.saleInvoice || "-",
        item.purchaseInvoice || "-",
        item.expenses || "-",
      ];
    });

    // Add header row
    formattedData.unshift([
      "S.No",
      "Last Updated",
      "Garage Name",
      "Total XLS Downloads",
      "Sale Invoice",
      "Purchase Invoice",
      "Expenses",
    ]);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the file
    XLSX.writeFile(wb, "exported_data.xlsx");
  };

  // Function to handle the garageId parameter from the CategoryForm
  const param = (garageId) => {
    setFilterCriteria((prev) => ({
      ...prev,
      garageId: garageId, // Set the garageId in the filter criteria
    }));
    applyFilters(); // Reapply filters when garageId changes
  };

  return (
    <>
      <Spin spinning={load}>
        <CategoryForm
          onSearch={handleFilterChange}
          Csv={handleExportExcel}
          fetchGarageListed={filteredGarageList} // This should be adjusted based on your needs
          param={param} // Pass the param function to CategoryForm
        />
        {!load && (
          <div className="vendor-manage-table">
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th2">Last Updated</th>
                  <th className="th4">Garage Name</th>
                  <th className="th4">Total XLS Downloads</th>
                  <th className="th4">Sale Invoice</th>
                  <th className="th4">Purchase Invoice</th>
                  <th className="th4">Expenses</th>
                </tr>
              </thead>
              <tbody>
                {filteredGarageList.length === 0 ? (
                  <tr>
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      No account XLSX tracking data found.
                    </td>
                  </tr>
                ) : (
                  visibleRows.map((item, index) => (
                    <tr key={item.garageId}>
                      <td>{startIndex + index + 1}</td>
                      <td>{moment(item.lastUpdated).format("DD-MM-YYYY")}</td>
                      <td>{item.garageName}</td>
                      <td>{item.totalXlsDownloads}</td>
                      <td>{item.saleInvoice}</td>
                      <td>{item.purchaseInvoice}</td>
                      <td>{item.expenses}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        )}
        <Pagination
          onChange={onChange}
          simple
          total={filteredGarageList.length}
          pageSize={itemsPerPage}
          style={{ marginTop: 10, float: "right" }}
          current={currentPage}
        />
      </Spin>
    </>
  );
};

export default XLSTrackingTable;
