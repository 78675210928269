import React, { useEffect, useState } from "react";
import "./Vendor.css";
import { Popover, Select } from "antd";
import { FaSearch, FaTimes } from "react-icons/fa";
import { TbFileExport } from "react-icons/tb";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";

const CategoryForm = ({ onSearch, Csv, fetchGarageListed, param }) => {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = useState("");

  const [disableToDate, setDisableToDate] = useState(true);
  const { Option } = Select;
  const [options, setOptions] = useState([]);
  const [load, setLoad] = useState(false);
  const token = localStorage.getItem("admin");
  const [source, setSource] = useState([]);
  const role = localStorage.getItem("role");
  const handleInputChange = (value) => {
    const suggestions = source.filter(
      (garage) =>
        typeof garage.garageName === "string" &&
        garage?.garageName?.toLowerCase().includes(value.toLowerCase())
    );
    setOptions(suggestions);
  };
  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedFromDate(selectedDate);
    setDisableToDate(false);
    setSelectedToDate(""); // Reset "To Date" when "From Date" changes
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleToDateChange = (e) => {
    setSelectedToDate(e.target.value);
    const filterCriteria = {
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status,
      search: searchTerm,
    };
    onSearch(filterCriteria);
  };

  const handleClear = () => {
    setSelectedFromDate("");
    setSelectedToDate("");
    setStatus("");
    setSearchTerm("");
    setDisableToDate(true);
  };

  const fetchGarageList = async (page) => {
    try {
      // fetchstart();
      setLoad(true);
      const response = await axios.get(
        `${ApiDomain.Domain}/garageNameList`,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data.data);
      if (response.status === 200) {
        setSource(response.data.data);
        // setCount(response.data.totalCount);
        setLoad(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchGarageList();
  }, []);
  const handleOptionClick = (option) => {
    const garageId = option.key;
    console.log(garageId); // Access the ID from the option object
    param(garageId);

    fetchGarageListed(garageId);

    // Use the garageId as needed
  };

  return (
    <div>
      <div className="vendor-manage-card-header">
        <form className="vendor-manage-form-control">
          <div className="sale-manage-input">
            <Select
              mode="multiple"
              showSearch
              placeholder="Search  Garage"
              style={{ width: 300 }}
              onChange={(e) => handleInputChange(value)}
              onSelect={(value, option) => handleOptionClick(option)}
            >
              {source?.map((suggestion) => (
                <Option key={suggestion._id} value={suggestion.garageName}>
                  <div style={{ display: "flex", gap: 10 }}>
                    <div
                      style={{
                        width: 50,
                        border: "1px solid black",
                        height: 35,
                        borderRadius: 8,
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "bold",
                          paddingTop: 7,
                        }}
                      >
                        {suggestion.garageId ? suggestion.garageId : "-"}
                      </p>
                    </div>
                    <div>
                      <p
                        style={{
                          marginLeft: 10,
                          padding: 0,
                          marginBottom: 0,
                          color: "#b11226",
                          fontWeight: "bold",
                        }}
                      >
                        {suggestion.garageName ? suggestion.garageName : "-"}
                      </p>
                      <p
                        style={{
                          marginLeft: 10,
                          padding: 0,
                          marginBottom: 0,
                          color: "grey",
                        }}
                      >
                        {suggestion.city ? suggestion.city : "-"}
                      </p>
                    </div>
                  </div>
                </Option>
              ))}
            </Select>
          </div>
          <div className="vendor-manage-input">
            {/* <label htmlFor='fromdate' style={{ fontWeight: "bold" }}>
              From Date
            </label> */}
            <input
              type="date"
              id="fromdate"
              name="fromdate"
              value={selectedFromDate}
              onChange={handleDateChange}
              placeholder="From Date"
              className="vendor-manage-input-cal"
            />
          </div>

          <div className="vendor-manage-input">
            {/* <label htmlFor='todate' style={{ fontWeight: "bold" }}>
              To Date
            </label> */}
            <input
              type="date"
              id="todate"
              name="todate"
              value={selectedToDate}
              onChange={handleToDateChange}
              placeholder="To Date"
              className="vendor-manage-input-cal"
              disabled={disableToDate}
              min={selectedFromDate}
            />
          </div>

          <div>
            <Popover content={"Clear Filters"} trigger="hover">
              <button
                type="button"
                className="vendor-manage-form-button"
                onClick={handleClear}
              >
                <CloseOutlined />
              </button>
            </Popover>
          </div>
          {role === "admin" && (
            <div onClick={Csv}>
              <Popover content={"Export CSV"} trigger="hover">
                <button type="button" className="vendor-manage-form-excel">
                  <TbFileExport />
                </button>
              </Popover>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CategoryForm;
