import React, { useState, useEffect } from "react";
import { Table, Pagination, Switch, Spin } from "antd";
import { CaretRightOutlined, UnlockOutlined } from "@ant-design/icons";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";

const Subscriptions = ({ garageId }) => {
  const [groupData, setGroupData] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("admin");

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${ApiDomain.Domain}/admin/subscriptionData`,
          { garageId },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const features = response?.data?.data?.features || [];
        // Group features by their category
        const grouped = features.reduce((acc, feature) => {
          const category = feature.featureCategory;
          if (!acc[category]) acc[category] = [];
          acc[category].push({
            ...feature,
            key: feature._id,
            status: feature.subscriptionStatus ? "ON" : "OFF",
          });
          return acc;
        }, {});
        setGroupData(Object.entries(grouped).map(([key, items]) => ({ category: key, items })));
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionData();
  }, [garageId, token]);

  const handlePanelChange = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const columns = [
    {
      title: "Feature Name",
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: "Activation Date",
      dataIndex: "activationDate",
      key: "activationDate",
      render: (text) => (
        <div>
          <UnlockOutlined
            style={{ transform: "scaleX(-1)", marginRight: "8px" }}
          />
          <span>Activation On: </span>
          <span style={{ color: "#b11336" }}>{text || "N/A"}</span>
        </div>
      ),
      align: "left",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <div style={{ display: "flex", justifyContent:"flex-end" }}>
          <span
            style={{
              marginRight: "8px",
              color: status === "ON" ? "grey" : "black",
            }}
          >
            {status === "OFF" ? "ON" : "OFF"}{" "}
          </span>
          <Switch
            defaultChecked={status === "ON"}
            style={{
              marginRight: "8px",
              backgroundColor: status === "ON" ? "green" : "black",
              borderColor: status === "ON" ? "green" : "black",
            }}
          />
          <span
            style={{
              marginRight: "8px",
              color: status === "OFF" ? "grey" : "black",
            }}
          >
            {status === "ON" ? "ON" : "OFF"}{" "}
          </span>
        </div>
      ),
      align: "left",
    },
  ];

  return (
    <div
      style={{
        padding: "0.5rem",
        backgroundColor: "white",
        borderRadius: "0.5rem",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", padding: "1rem" }}>
          <Spin size="large" />
        </div>
      ) : (
        groupData.map((section) => (
          <div key={section.category} style={{ marginBottom: "1rem" }}>
            <div
              onClick={() => handlePanelChange(section.category)}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem",
                cursor: "pointer",
                border: "1px solid #ddd",
                borderRadius: "0.25rem",
              }}
            >
              <strong>{section.category}</strong>
              <CaretRightOutlined
                rotate={activeKey === section.category ? 90 : 0}
                style={{ fontSize: "1rem", color: "#555" }}
              />
            </div>

            {activeKey === section.category && (
              <div style={{ paddingLeft: "1rem",
              paddingRight:"1rem" , backgroundColor: "#f9f9f9" }}>
                {section.items.length > 0 ? (
                  <Table
                    showHeader={false}
                    columns={columns}
                    dataSource={section.items}
                    pagination={false}
                    rowKey="key"
                    style={{backgroundColor: "#f9f9f9"}}
                  />
                ) : (
                  <div style={{ padding: "1rem", color: "gray" }}>
                    No features available in this category.
                  </div>
                )}
              </div>
            )}
          </div>
        ))
      )}
      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
        <Pagination defaultCurrent={1} total={groupData.length * 10} />
      </div>
    </div>
  );
};

export default Subscriptions;
