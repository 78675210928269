import React, { useState } from "react";
import { Button, Space } from "antd";
import PaymentPage from "./PaymentPage";
import Subscriptions from "./Subscriptions";
import SubscriptionHistory from "./SubscriptionHistory";
import CategoryForm from "./CategoryForm";

const TabsComponent = ({garageId}) => {
  const [activeTab, setActiveTab] = useState("1");
  console.log("garageIdData:",garageId)
  const renderTabContent = () => {
    switch (activeTab) {
      case "1":
        return <Subscriptions garageId={garageId} />;
      case "2":
        return <SubscriptionHistory garageId={garageId} />;
      case "3":
        return <PaymentPage garageId={garageId} />;
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        // backgroundColor: "#f5f5f5",
        borderRadius: "8px",
        padding: "10px",
      }}
    >
      <Space style={{ marginBottom: "1rem", display: "flex", gap: "1rem" }}>
        <Button
          style={{
            backgroundColor: activeTab === "1" ? "#b11336" : "#fff",
            color: activeTab === "1" ? "#fff" : "#b11336",
            borderColor: "#b11336",
          }}
          onClick={() => setActiveTab("1")}
        >
          Subscription
        </Button>
        <Button
          style={{
            backgroundColor: activeTab === "2" ? "#b11336" : "#fff",
            color: activeTab === "2" ? "#fff" : "#b11336",
            borderColor: "#b11336",
          }}
          onClick={() => setActiveTab("2")}
        >
          Subscription History
        </Button>
        <Button
          style={{
            backgroundColor: activeTab === "3" ? "#b11336" : "#fff",
            color: activeTab === "3" ? "#fff" : "#b11336",
            borderColor: "#b11336",
          }}
          onClick={() => setActiveTab("3")}
        >
          Payment Link
        </Button>
      </Space>

      {/* Render Tab Content */}
      <div
        style={{
          // padding: "20px",
          // backgroundColor: "#f5f5f5",
          borderRadius: "8px",
        }}
      >
        <div style={{display:"flex" ,justifyContent:"space-between"}}>
          <CategoryForm 
          onSearch={''}
          ExportCsv={''}/>
          <span style={{color:"#b11336"}}>Expires on 23 November</span>
        </div>
        {renderTabContent()}
      </div>
    </div>
  );
};

export default TabsComponent;
