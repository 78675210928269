import { Button, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ApiDomain from "../../../api/ApiDomain";

const subscriptionData = [
  {
    key: "1",
    serialNo: 1,
    plan: "Launch Plan",
    activationDate: "08-10-2024",
    expiryDate: "08-10-2024",
    timePeriod: "3 Months",
    status: "Expiring in 3 Days",
    actionEnabled: true,
  },
  {
    key: "2",
    serialNo: 2,
    plan: "Launch Plan",
    activationDate: "08-10-2024",
    expiryDate: "08-10-2024",
    timePeriod: "3 Months",
    status: "Expired",
    actionEnabled: false,
  },
  {
    key: "3",
    serialNo: 3,
    plan: "Launch Plan",
    activationDate: "08-10-2024",
    expiryDate: "08-10-2024",
    timePeriod: "3 Months",
    status: "Expired",
    actionEnabled: false,
  },
  {
    key: "4",
    serialNo: 4,
    plan: "Rise Plan",
    activationDate: "08-10-2024",
    expiryDate: "08-10-2024",
    timePeriod: "3 Months",
    status: "Expired",
    actionEnabled: false,
  },
];

const SubscriptionHistory = ({ garageId }) => {
  const [loading, setLoading] = useState("false");
  const [subscriptionHistory, setSubscriptionHistory] = useState([]);

  const token = localStorage.getItem("admin");

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${ApiDomain.Domain}/admin/subscrptionHistory`,
          { garageId },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        // Transform API response to match table data structure
        const formattedData = response?.data?.data.map((item, index) => ({
          key: item._id,
          serialNo: index + 1,
          plan: item.plan,
          activationDate: new Date(item.date).toLocaleDateString(),
          expiryDate: new Date(item.expiryDate).toLocaleDateString(),
          timePeriod: calculateTimePeriod(item.date, item.expiryDate),
          status: getStatus(item.expiryDate),
          actionEnabled: new Date(item.expiryDate) > new Date(),
        }));
        setSubscriptionHistory(formattedData);
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionData();
  }, [garageId, token]);

  // Helper function to calculate the time period
  const calculateTimePeriod = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const months =
      end.getMonth() -
      start.getMonth() +
      12 * (end.getFullYear() - start.getFullYear());
    return `${months} Month${months > 1 ? "s" : ""}`;
  };

  // Helper function to determine subscription status
  const getStatus = (expiryDate) => {
    const today = new Date();
    const expiry = new Date(expiryDate);
    const daysLeft = Math.ceil((expiry - today) / (1000 * 60 * 60 * 24));

    if (daysLeft > 0) {
      return `Expiring in ${daysLeft} Day${daysLeft > 1 ? "s" : ""}`;
    }
    return "Expired";
  };

  console.log(subscriptionData);

  const columns = [
    {
      title: "S.No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text) => <span style={{ fontWeight: "bold" }}>{text}</span>,
    },
    {
      title: "Subscription Plan",
      dataIndex: "plan",
      key: "plan",
      render: (text) => (
        <span style={{ color: "red", fontWeight: "bold" }}>{text}</span>
      ),
    },
    {
      title: "Activation Date",
      dataIndex: "activationDate",
      key: "activationDate",
    },
    {
      title: "Expiry Date",
      dataIndex: "expiryDate",
      key: "expiryDate",
    },
    {
      title: "Time Period",
      dataIndex: "timePeriod",
      key: "timePeriod",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span style={{ color: text.includes("Expiring") ? "red" : "gray" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "actionEnabled",
      key: "actionEnabled",
      render: (enabled) => (
        <Button
          type="primary"
          disabled={!enabled}
          style={{
            backgroundColor: enabled ? "#28B779" : "#d9d9d9",
            color: enabled ? "white" : "#595959",
            borderColor: enabled ? "#28B779" : "#d9d9d9", 
          }}
        >
          Send Reminder
        </Button>
      ),
    },
  ];

  return (
    <div
      style={{ padding: "1rem", backgroundColor: "white", borderRadius: "8px" }}
    >
      <Table
        loading={loading}
        dataSource={subscriptionHistory}
        columns={columns}
        pagination={{ pageSize: 4, position: ["bottomCenter"] }}
      />
    </div>
  );
};

export default SubscriptionHistory;
