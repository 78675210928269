import React, { useState, useEffect } from "react";
import { Popover } from "antd";
import { TbFileExport } from "react-icons/tb";
import { MdClear } from "react-icons/md";

const CategoryForm = ({ onSearch, ExportCsv }) => {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [status, setStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [disableToDate, setDisableToDate] = useState(true);
  const [minimumToDate, setMinimumToDate] = useState(null);
  const role = localStorage.getItem("role");

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedFromDate(selectedDate);
    setDisableToDate(false);
    setMinimumToDate(selectedDate);
    onSearch({
      fromDate: selectedDate,
      toDate: selectedToDate,
      status: status,
      search: searchTerm,
    });
  };

  const handleToDateChange = (e) => {
    const selectedDate = e.target.value;
    setSelectedToDate(selectedDate);
    onSearch({
      fromDate: selectedFromDate,
      toDate: selectedDate,
      status: status,
      search: searchTerm,
    });
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    onSearch({
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status: e.target.value,
      search: searchTerm,
    });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    onSearch({
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      status: status,
      search: e.target.value,
    });
  };

  const handleClear = () => {
    setSelectedFromDate("");
    setSelectedToDate("");
    setStatus("");
    setSearchTerm("");
    setDisableToDate(true);
    setMinimumToDate(null);
    onSearch({
      fromDate: "",
      toDate: "",
      status: "",
      search: "",
    });
  };

  useEffect(() => {
    setSearchTerm("");
  }, [searchTerm]);

  return (
    <div style={{ padding: '1rem',backgroundColor:"#fff" }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <form style={{ display: 'flex', alignItems: 'center', gap: '1rem', overflowX: 'auto' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <input 
              type="date"
              name="fromdate"
              value={selectedFromDate}
              onChange={handleDateChange}
              placeholder="From Date"
              style={{
                border: 'none',
                borderBottom: '1px solid #ccc',
                background: '#fffefe',
                fontSize: '15px',
                fontWeight: '400',
                width: '170px',
              }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <input
              type="date"
              name="todate"
              value={selectedToDate}
              onChange={handleToDateChange}
              min={minimumToDate}
              placeholder="To Date"
              style={{
                border: 'none',
                borderBottom: '1px solid #ccc',
                background: '#fffefe',
                fontSize: '15px',
                fontWeight: '400',
                width: '170px',
              }}
              disabled={disableToDate}
              title={disableToDate ? "Please select From Date first" : ""}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <select
              value={status}
              name="status"
              onChange={handleStatusChange}
              style={{
                border: 'none',
                borderBottom: '1px solid #ccc',
                background: '#fffefe',
                fontSize: '15px',
                fontWeight: '400',
                width: '170px',
              }}
            >
              <option value="" disabled>Choose an option</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <input 
              type="search"
              name="search"
              placeholder="Search..."
              style={{
                fontSize: '0.875rem',
                width: '200px',
                border: '1px solid #e9ecef',
                backgroundClip: 'padding-box',
              }}
              onChange={handleSearchChange}
            />
          </div>
          <div>
            <Popover content="Clear Filters" trigger="hover">
              <button
                type="button"
                style={{
                  borderRadius: '5px',
                  marginRight: '4px',
                  paddingTop: '6px',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  backgroundColor: '#b11226',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer',
                }}
                onClick={handleClear}
              >
                <MdClear />
              </button>
            </Popover>
          </div>
          {role === "admin" && (
            <div onClick={ExportCsv}>
              <Popover content="Export CSV" trigger="hover">
                <button
                  type="button"
                  style={{
                    borderRadius: '5px',
                    marginRight: '4px',
                    paddingTop: '6px',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    backgroundColor: '#358053',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <TbFileExport />
                </button>
              </Popover>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CategoryForm;
