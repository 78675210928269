import React, { useEffect, useState } from "react";
import "./Garage.css";
import { FaLock, FaLockOpen, FaPencilAlt, FaUnlockAlt } from "react-icons/fa";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import EditCategory from "./EditCategory";
import moment from "moment";
import CategoryForm from "./Categoryform";
import {
  Avatar,
  Col,
  Form,
  Input,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Spin,
  message,
  Button,
} from "antd";
import CircularProgress from "../../../components/CircularProgress";
import ApiDomain from "../../../api/ApiDomain";
import axios from "axios";
import * as XLSX from "xlsx";
import ViewServices from "./viewServices";
import MultipleImageUpload from "../../ApproveGarage/ImageUpload";
import TabsComponent from "../Subscriptions";

const CategoryTable = ({ garageList, load, fetchGarageList }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGarage, setSelectedGarage] = useState(null); // Added state to store selected garage details

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [id, setId] = useState("");
  const [active, setActive] = useState(false);
  const itemsPerPage = 10; // Adjusted to display 6 items per page
  const pageSize = 10; // Number of rows per page
  console.log(fromDate);
  const [currentPage, setCurrentPage] = useState(1);
  const [date, setDate] = useState("");
  const [data, setData] = useState([]);
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [modalXV, setModalXV] = useState(false);
  const [modalXV1, setModalXV1] = useState(false);
  const [modalXV2, setModalXV2] = useState(false);
  const [modalXV3, setModalXV3] = useState(false);
  const [modalXV4, setModalXV4] = useState(false);
  const [modalXV8, setModalXV8] = useState(false);
  const [image, setImages] = useState("");
  const [social, setSocial] = useState(false);
  const [logoOptions, setLogoOptions] = useState([]);

  const [modalXV7, setModalXV7] = useState(false);
  const [modalXV9, setModalXV9] = useState(false);

  const [modalXV5, setModalXV5] = useState(false);

  const [modalXV6, setModalXV6] = useState(false);
  const [modalXV10, setModalXV10] = useState(false);

  const [form] = Form.useForm();
  const [category, setCategory] = useState([]);
  const [services, setServices] = useState([]);
  const { Option } = Select;
  const [initialSelectedServiceIds, setInitialSelectedServiceIds] = useState(
    []
  );
  const [initialCategoryNames, setInitialCategoryNames] = useState([]);
  const [initialValue, setInitialValue] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [operation, setOperation] = useState("+");
  const [result, setResult] = useState(0);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);

    // Try to parse the input as a number
    const num = parseFloat(inputValue);

    if (isNaN(num)) {
      // Handle invalid input
      console.log("Invalid input, not a number:", inputValue);
      setResult(null); // Or you can set it to a specific value, e.g., 0 or an error message
    } else {
      // Handle valid input
      setResult(num);
    }
  };

  console.log(result);

  const handleOperationChange = (value) => {
    setOperation(value);
  };
  const handleCalculate = () => {
    // Prepare the payload
    const payload = {
      garageId: selectedGarage.garageId,
      updateCoins: result,
      paymentType: "add", // Default value, can be overridden below
    };

    // Set paymentType based on the operation
    if (operation === "+") {
      payload.paymentType = "add"; // Use '=' instead of ':'
    } else if (operation === "-") {
      payload.paymentType = "sub"; // Use '=' instead of ':'
    } else {
      message.error("Invalid operation");
      return;
    }

    // Send the payload via an API call
    axios
      .put(`${ApiDomain.Domain}/updateCoins`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        message.success("Operation successfully submitted");
        setModalXV9(false);
        fetchGarageList();
      })
      .catch(() => {
        message.error("Failed to submit operation");
      });
  };

  const handleCalculateCancel = () => {
    setModalXV9(false);
  };

  const [garageIdData, setGarageIdData] = useState("");
  const [filterCriteria, setFilterCriteria] = useState({
    fromDate: "",
    toDate: "",
    status: "",
    search: "",
  });

  const fetchServiceLists = async (id) => {
    console.log(id);
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/category_service_list`,
        {
          categoryId: id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response?.data?.data);
      setServices(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchServiceLists();
  }, []);

  useEffect(() => {
    // Assuming selectedGarage is populated with data containing categories
    const categories = selectedGarage?.categories?.map(
      (category) => category.services
    );
    setInitialCategoryNames(categories);
  }, [selectedGarage]);

  useEffect(() => {
    applyFilters();
  }, [filterCriteria, garageList]);

  const applyFilters = () => {
    const filteredList = garageList
      .filter((item) => item.accountStatus === "active")
      .filter((item) => {
        // Standardize the date format for comparison
        const formattedDate = moment(
          item?.registationDate,
          ["DD-MM-YYYY", "YYYY-MM-DD"],
          true
        );

        // Filtering logic based on fromDate, toDate, status, and search
        const fromDateMatch =
          !filterCriteria.fromDate ||
          formattedDate.isSameOrAfter(
            moment(filterCriteria.fromDate, "YYYY-MM-DD"),
            "day"
          );

        const toDateMatch =
          !filterCriteria.toDate ||
          formattedDate.isSameOrBefore(
            moment(filterCriteria.toDate, "YYYY-MM-DD"),
            "day"
          );

        const statusMatch =
          !filterCriteria.status ||
          (item.status ? "active" : "inactive") === filterCriteria.status;

        const searchMatch =
          !filterCriteria.search ||
          (item.garageName &&
            item.garageName
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.garageOwnerName &&
            item.garageOwnerName
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.vecrepId &&
            item.vecrepId
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.phoneNumber &&
            item.phoneNumber
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.email &&
            item.email
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.address &&
            item.address
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.state &&
            item.state
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.city &&
            item.city
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.pancardNumber &&
            item.pancardNumber
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.gstNumber &&
            item.gstNumber
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase())) ||
          (item.businessType &&
            item.businessType
              .toLowerCase()
              .includes(filterCriteria.search.toLowerCase()));

        return fromDateMatch && toDateMatch && statusMatch && searchMatch;
      })
      .sort((a, b) => {
        // Sorting logic based on your requirements
        return new Date(b.registationDate) - new Date(a.registationDate);
      });

    setFilteredGarageList(filteredList);
  };

  const handleFilterChange = (values) => {
    setFilterCriteria(values);
  };
  useEffect(() => {
    if (selectedGarage) {
      fetchServiceLists(selectedGarage?.categoryId);
    }
  }, [selectedGarage]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleRows = filteredGarageList.slice(startIndex, endIndex);
  console.log(visibleRows);
  const handleOpenModal = (index) => {
    setSelectedGarage(visibleRows[index]); // Updated to use visibleRows
    setIsModalVisible(true);
  };
  const handleSubmit = (values) => {
    // Send socialMediaList data to your API endpoint
    console.log("Submitting data:", socialMediaList, social.garageId);
    const requestData = {
      garageId: social?.garageId,
      socialMedias: socialMediaList,

      // Make sure to define selectedCategories state
    };
    console.log(requestData);
    axios
      .put(`${ApiDomain.Domain}/update_SocialMedias`, requestData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("API Response:", response);
        console.log(response?.data?.responseCode);
        if (response.data?.responseCode === 200) {
          fetchGarageList();
          cancelx();
          message.success(response?.data?.message);
        } else if (response.data?.responseCode === 400) {
          message.warning(response.data?.message);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
    // Here, you can make an API call to submit the social media data
  };

  const token = localStorage.getItem("admin");

  const handleCloseModal = () => {
    setSelectedGarage(null);
    setIsModalVisible(false);
    form.resetFields(); // Reset form fields to their initial values

    setModalXV1(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("edit-category-modal-overlay")) {
      handleCloseModal();
    }
  };

  const modalX = (index, id) => {
    setModalXV(true);
    const selectedGarage = visibleRows[index]; // Get the selected garage

    setSelectedGarage(selectedGarage); // Updated to use visibleRows

    setGarageIdData(id);
    fetchGarageListSingle(id);
  };

  const modalX1 = (index, id) => {
    setModalXV1(true);
    // setGarageId(id)
    const selectedGarage = visibleRows[index]; // Get the selected garage
    setSelectedGarage(selectedGarage);
    setGarageIdData(id);
    // fetchGarageLists(id);
  };

  const modalX2 = (index, id) => {
    setModalXV2(true);
    // setGarageId(id)
    const selectedGarage = visibleRows[index]; // Get the selected garage
    setSelectedGarage(selectedGarage);
    setGarageIdData(id);
    // fetchGarageLists(id);
  };
  const modalX3 = (index, id) => {
    setModalXV3(true);
    // setGarageId(id)
    const selectedGarage = visibleRows[index]; // Get the selected garage
    setSelectedGarage(selectedGarage);
    setGarageIdData(id);
    // fetchGarageLists(id);
  };
  const modalX4 = (index, id) => {
    setModalXV4(true);
    // setGarageId(id)
    const selectedGarage = visibleRows[index]; // Get the selected garage
    setSelectedGarage(selectedGarage);
    setGarageIdData(id);
    // fetchGarageLists(id);
  };
  const modalX5 = (index, id) => {
    setModalXV5(true);
    // setGarageId(id)
    const selectedGarage = visibleRows[index]; // Get the selected garage
    setSelectedGarage(selectedGarage);
    setGarageIdData(id);
    // fetchGarageLists(id);
  };
  const modalX7 = (index, id) => {
    setImages("");

    setModalXV7(true);
    // setGarageId(id)
    const selectedGarage = visibleRows[index]; // Get the selected garage
    setSelectedGarage(selectedGarage);
    setGarageIdData(id);
    // fetchGarageLists(id);
  };
  const modalX8 = (index, id) => {
    setModalXV8(true);
    // setGarageId(id)
    const selectedGarage = visibleRows[index];
    setSelectedGarage(selectedGarage);
    setGarageIdData(id);
    // fetchGarageLists(id);
  };

  const modalX10 = (index, id) => {
    console.log("garageId : ", id);
    setModalXV10(true);
    // setGarageId(id)
    const selectedGarage = visibleRows[index]; 
    setSelectedGarage(selectedGarage);
    setGarageIdData(id);
    // fetchGarageLists(id);
  };

  const modalX9 = (index, id) => {
    setModalXV9(true);
    // setGarageId(id)
    const selectedGarage = visibleRows[index]; // Get the selected garage
    console.log(selectedGarage, id, "svfdvs");

    setSelectedGarage(selectedGarage);
    setInitialValue(selectedGarage.coins);
    // setResult(selectedGarage.coins);
    setGarageIdData(id);
    // fetchGarageLists(id);
  };
  // Update initial selected service IDs when selectedGarage changes
  // useEffect(() => {
  //   if (selectedGarage) {
  //     const services = selectedGarage?.categories.map(category =>
  //       category?.services.map(service => service?.serviceName)
  //     ).flat();
  //     setInitialSelectedServiceIds(services);
  //   }
  // }, [selectedGarage]);

  console.log(initialSelectedServiceIds);
  const cancelx = () => {
    setModalXV(false);
    setModalXV1(false);
    setModalXV2(false);
    setModalXV3(false);
    setModalXV4(false);
    setModalXV5(false);
    setModalXV6(false);
    setModalXV7(false);
    setModalXV8(false);
    setModalXV9(false);
    setModalXV10(false);
    setSelectedImage(null);
  };

  async function handleSubmit2(values) {
    console.log(values);

    try {
      const response = await axios.put(
        `${ApiDomain.Domain}/manageGarageProfile`,
        { garageId: id, ...values, status: active },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      if (response?.data?.responseCode === 200) {
        fetchGarageList();
        message.success("Status Updated Successfully");
        handleCancel();
      }

      // Update the garage list after successful approval
    } catch (error) {
      console.log(error.message);
    }
  }

  const fetchGarageListSingle = async (id) => {
    try {
      const response = await axios.post(
        `${ApiDomain.Domain}/getListOfBankDetails`,
        {
          garageId: id ? id : "",

          // Use the current value of the 'page' state
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response);
      if (response.status === 200) {
        console.log(response.data);

        setData(response.data.data);

        // setCount(response.data.totalCount);
        console.log(response.data.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const onChange = (page) => {
    setCurrentPage(page);
  };

  const handleCancel = () => {
    setModalXV1(false);
    setModal(false);
    form.resetFields(); // Reset form fields to their initial values
  };
  console.log(garageList.length);
  console.log(selectedGarage?.workingHours?.length === 0);

  const activeStatus = (item) => {
    setId(item.garageId);
    setActive(!item.status);
    setModal(true);
  };
  const handleExportExcel = () => {
    const formattedData = filteredGarageList.map((item, index) => {
      const formattedDate = moment(item?.registationDate).format("DD-MM-YYYY");

      // Extracting category names from the list of objects
      const categories = item.categories
        ? item.categories.map((category) => category.categoryName).join(", ")
        : "";
      const services = item.services
        ? item.services.map((service) => service.serviceName).join(", ")
        : "";

      // Extracting bank details
      const bankDetails = item?.banks
        ? item?.banks
            ?.map((bank) => {
              return `${bank?.nameAsPerBankAccount} - ${bank?.bankName} (${bank?.accountNumber})`;
            })
            .join(", ")
        : "";

      // Extracting social media details
      const socialMediaDetails = item?.socialMedias
        ? item?.socialMedias
            ?.map((socialMedia) => {
              return `${socialMedia?.socialMediaName} - ${socialMedia?.link}`;
            })
            .join(", ")
        : "";

      // Extracting working hours
      const workingHours = item.workingHours
        ? item.workingHours
            ?.map((workingHour) => {
              return `${workingHour?.Day}: ${workingHour?.StartTime} - ${workingHour?.EndTime}`;
            })
            .join(", ")
        : "";

      return [
        index + 1,
        formattedDate || "",
        item.vecrepId || "",
        item.garageName || "",
        item.garageOwnerName || "", // Owner Name
        item.email || "",
        item.phoneNumber || "",
        item.address || "",
        item.state || "",
        item.city || "", // City - Not available in the data
        item.area || "", // Area - Not available in the data
        item.pancardNumber || "",
        item.gstNumber || "N/A",
        item.businessType || "",
        item.garageType === 1
          ? "Car"
          : item.garageType === 2
          ? "Bike"
          : item.garageType === 3
          ? "Both"
          : "-",
        categories, // Categories
        services, // Services
        bankDetails, // Bank Details
        item.urlLink,
        socialMediaDetails, // Social Media
        workingHours, // Working Hours
        item.status ? "Active" : "Inactive",
      ];
    });

    // Add header row
    formattedData.unshift([
      "S.No",
      "Register Date",
      "Vecrep Id",
      "Garage Name",
      "Owner Name",
      "Email",
      "Mobile Number",
      "Address",
      "State",
      "City",
      "Area",
      "PAN Number",
      "GST Number",
      "Business Type",
      "Vehicle Type",
      "Categories",
      "Services",
      "Bank Details",
      "Url",
      "Social Media",
      "Working Hours",
      "Status",
    ]);

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(formattedData);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the file
    XLSX.writeFile(wb, "exported_data.xlsx");
  };

  const a = selectedGarage?.categories?.map((category) =>
    category?.services?.map((service) => service.serviceName)
  );

  const socialmedia = (selectedGarage) => {
    setSocial(selectedGarage);
    setModalXV3(false);
    setModalXV6(true);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const fetchGarageListLogo = async () => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/listOfSocialMedias`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data);
      if (response.status === 200) {
        setLogoOptions(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    fetchGarageListLogo();
  }, []);
  const [socialMediaList, setSocialMediaList] = useState([
    { link: "", logo: "", socialMediaName: "" },
  ]);

  const handleAddSocialMedia = () => {
    setSocialMediaList([
      ...socialMediaList,
      { link: "", logo: "", socialMediaName: "" },
    ]);
  };

  const handleSocialMediaChange = (index, field, value) => {
    const updatedList = [...socialMediaList];
    updatedList[index][field] = value;
    setSocialMediaList(updatedList);
  };
  const handleSocialMediaChange1 = (index, field, value) => {
    const updatedList = [...socialMediaList];
    updatedList[index][field] = value;
    setSocialMediaList(updatedList);
  };

  const handleRemoveSocialMedia = (index) => {
    const updatedList = socialMediaList.filter((_, i) => i !== index);
    setSocialMediaList(updatedList);
  };
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageHover = (image) => {
    setSelectedImage(image);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  console.log(image);

  return (
    <>
      {modal && (
        <Modal
          footer={null}
          centered
          visible={modal}
          onCancel={handleCancel}
          width={"auto"}
        >
          <div className="">
            <div className="add-garage-manage-modal-content-header">
              <div>
                <h3 style={{ color: "#b11226" }}>Garage Status</h3>
              </div>
            </div>
            <div className="add-category-modal-form">
              <Spin spinning={load}>
                <Form
                  // form={form}
                  layout="vertical"
                  style={{ minWidth: "600px" }}
                  onFinish={handleSubmit2}
                >
                  <div>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "80%", marginTop: 10 }}>
                        <Col xl={24} lg={24}>
                          <Form.Item label="Reason : " name="reason">
                            <Input
                              placeholder="Enter Your Reason Name"
                              style={{}}
                            />
                          </Form.Item>
                        </Col>
                      </div>
                    </Row>
                  </div>
                  <div className="add-garage-manage-popup-footer">
                    <button
                      className="cancel-button"
                      onClick={handleCancel}
                      style={{ marginRight: 8 }}
                    >
                      Cancel
                    </button>

                    <button className="submit-button" htmlType="submit">
                      Submit
                    </button>
                  </div>
                </Form>
              </Spin>
            </div>
          </div>
        </Modal>
      )}

      <Spin spinning={load}>
        <CategoryForm
          onSearch={handleFilterChange}
          ExportCsv={handleExportExcel}
        />
        {!load && (
          <div className="garage-manage-table" onClick={handleOverlayClick}>
            <table>
              <thead>
                <tr>
                  <th className="th1">S.No</th>
                  <th className="th2">Garage Image</th>
                  <th className="th3">Register Date</th>
                  <th className="th4">Vecrep Id</th>
                  <th className="th4">Coins</th>

                  <th className="th5">Garage Name</th>
                  <th className="th5">Owner Name</th>

                  <th className="th6">Email</th>
                  <th className="th5">Mobile Number</th>

                  <th className="th5">Address</th>
                  <th className="th5">State</th>
                  <th className="th5">City</th>
                  <th className="th5">Area</th>

                  <th className="th8">PAN Number</th>
                  <th className="th9">GST Number</th>
                  <th className="th5">Business Type</th>
                  <th className="th5">Vehicle Type</th>
                  <th className="th5">Refer-Code</th>

                  <th className="th5">Categories</th>

                  <th className="th12">View Services</th>
                  <th className="th12">Images</th>

                  <th className="th13">Bank Details</th>
                  <th className="th13">Social Media</th>
                  <th className="th13">URL Link</th>
                  <th className="th13">Subscriptions</th>
                  <th className="th13">Working Hours</th>
                  <th className="th10">Status</th>
                  <th className="th11">Action</th>
                </tr>
              </thead>

              <tbody>
                {filteredGarageList.length === 0 ? (
                  <tr>
                    <td colSpan="13" style={{ textAlign: "center" }}>
                      No garages found.
                    </td>
                  </tr>
                ) : (
                  visibleRows.map((item, index) => {
                    const words = item?.garageName?.split(" ");

                    const formattedDate = moment(item?.registationDate).format(
                      "DD-MM-YYYY"
                    );
                    const lengthCategories = item?.categories?.length;
                    const lengthService = item?.services?.length;

                    return (
                      <tr key={item.id}>
                        <td>{startIndex + index + 1}</td>
                        <td>
                          <div className="table-image">
                            {item.imagePath ? (
                              <img src={item.imagePath} alt="" />
                            ) : (
                              <Avatar
                                shape="square"
                                size={40}
                                style={{
                                  backgroundColor: "#b11226",
                                  fontWeight: 600,
                                  fontSize: 17,
                                }}
                              >
                                {words.length > 0 &&
                                  words[0]?.charAt(0)?.toUpperCase()}
                                {words.length > 1 &&
                                  words[1]?.charAt(0)?.toUpperCase()}
                              </Avatar>
                            )}
                          </div>
                        </td>
                        <td>{formattedDate || "-"}</td>
                        <td>{item.vecrepId || "-"}</td>
                        <td
                          onClick={(e) => modalX9(index, item.garageId)}
                          style={{
                            color: "blueviolet",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          {item.coins || "-"}
                        </td>
                        <td>{item.garageName || "-"}</td>
                        <td>{item.garageOwnerName || "-"}</td>
                        <td>{item.email || "-"}</td>
                        <td>{item.phoneNumber || "-"}</td>
                        <td>{item.address || "-"}</td>
                        <td>{item.state || "-"}</td>
                        <td>{item.city || "-"}</td>
                        <td>{item.area || "-"}</td>

                        <td>{item.pancardNumber || "-"}</td>
                        <td>{item.gstNumber || "N/A"}</td>
                        <td>{item.businessType || "-"}</td>
                        <td>
                          {item.garageType === 1
                            ? "Car"
                            : item.garageType === 2
                            ? "Bike"
                            : item.garageType === 3
                            ? "Both"
                            : "-"}
                        </td>
                        <td onClick={(e) => modalX8(index, item.garageId)}>
                          <span
                            className="badge2 badge-success2"
                            style={{ cursor: "pointer" }}
                          >
                            View
                          </span>
                        </td>

                        <td onClick={(e) => modalX2(index, item.garageId)}>
                          <span
                            className="badge2 badge-success2"
                            style={{ cursor: "pointer" }}
                          >
                            View
                          </span>
                          <span style={{ paddingRight: 5 }}>
                            {" "}
                            {`(${lengthCategories || "0"})`}
                          </span>
                        </td>
                        <td onClick={(e) => modalX1(index, item.garageId)}>
                          <span
                            className="badge2 badge-success2"
                            style={{ cursor: "pointer" }}
                          >
                            View
                          </span>
                          <span style={{ paddingRight: 5 }}>
                            {" "}
                            {`(${lengthService || "0"})`}
                          </span>
                        </td>
                        <td onClick={(e) => modalX7(index, item.garageId)}>
                          <span
                            className="badge2 badge-success2"
                            style={{ cursor: "pointer" }}
                          >
                            View
                          </span>
                        </td>
                        <td onClick={(e) => modalX(index, item.garageId)}>
                          <span
                            className="badge2 badge-success2"
                            style={{ cursor: "pointer" }}
                          >
                            View
                          </span>
                        </td>
                        <td onClick={(e) => modalX3(index, item.garageId)}>
                          <span
                            className="badge2 badge-success2"
                            style={{ cursor: "pointer" }}
                          >
                            View
                          </span>
                        </td>
                        <td onClick={(e) => modalX4(index, item.garageId)}>
                          <span
                            className="badge2 badge-success2"
                            style={{ cursor: "pointer" }}
                          >
                            View
                          </span>
                        </td>
                        <td onClick={(e) => modalX10(index, item.garageId)}>
                          <span
                            className="badge2 badge-success2"
                            style={{ cursor: "pointer" }}
                          >
                            View
                          </span>
                        </td>
                        <td onClick={(e) => modalX5(index, item.garageId)}>
                          <span
                            className="badge2 badge-success2"
                            style={{ cursor: "pointer" }}
                          >
                            View
                          </span>
                        </td>
                        <td>
                          <span
                            className={`badge badge-${
                              item.status ? "success" : "danger"
                            }`}
                            // onClick={() => activeStatus(item)}
                          >
                            {item.status ? "Active" : "InActive"}
                          </span>
                        </td>
                        <td>
                          <div className="action-cell">
                            <div
                              className="edit-cell"
                              onClick={() => handleOpenModal(index)}
                            >
                              <FaPencilAlt />
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="action-cell">
                            <div
                              className="edit-cell"
                              onClick={() => activeStatus(item)}
                            >
                              {item.status ? <FaLockOpen /> : <FaLock />}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>

            <div>
              {modalXV8 && (
                <Modal
                  footer={null}
                  centered
                  visible={modalXV8}
                  onCancel={cancelx}
                  width={600}
                >
                  <div className="garage-manage-table">
                    <div className="add-subscription-manage-modal-content-header">
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                          ReferCode Details
                        </h3>
                      </div>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th className="th4">My ReferCode</th>
                          <th className="th4">Total Referal</th>
                          <th className="th4">Premium Referal</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>{selectedGarage?.garageReferenceCode || "-"}</td>
                          <td>{selectedGarage?.referalsCount}</td>
                          <td>{selectedGarage?.premiumReferal || "-"}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Modal>
              )}
            </div>
            <div>
              {modalXV2 && (
                <Modal
                  footer={null}
                  centered
                  visible={modalXV2}
                  onCancel={cancelx}
                  width={"auto"}
                >
                  <div>
                    <div className="add-subscription-manage-modal-content-header">
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                          Categories
                        </h3>
                      </div>
                    </div>

                    {selectedGarage?.categories?.length > 0 ? (
                      <div
                        style={{
                          width: "1000px",
                          height: "200px",
                          maxHeight: "1000px",
                          overflowY: "scroll",
                        }}
                      >
                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            margin: 10,
                          }}
                        >
                          {selectedGarage.categories.map((each) => (
                            <Col xl={6} lg={6}>
                              <h5
                                style={{
                                  border: "1px solid black",
                                  padding: "1px 10px 1px 10px",
                                }}
                              >
                                {each.categoryName}
                              </h5>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                          color: "black",
                          margin: 10,
                        }}
                      >
                        No categories to display
                      </div>
                    )}
                  </div>
                </Modal>
              )}
            </div>

            <div>
              {modalXV3 && (
                <Modal
                  footer={null}
                  centered
                  visible={modalXV3}
                  onCancel={cancelx}
                  width={"auto"}
                >
                  <div className="garage-manage-table">
                    <div className="add-subscription-manage-modal-content-header">
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                          Social Media
                        </h3>
                      </div>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th className="th1">S.No</th>
                          <th className="th4">Social Media Name</th>
                          <th className="th4">Link</th>
                          <th className="th4">Logo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedGarage?.socialMedias?.length !== 0 ? (
                          selectedGarage?.socialMedias?.map((item, index) => {
                            console.log("Item:", item); // Debugging
                            return (
                              <tr key={item._id}>
                                <td>{startIndex + index + 1}</td>
                                <td>{item?.socialMediaName}</td>
                                <td>
                                  <a
                                    href={`https://${selectedGarage?.urlLink}`}
                                    target="_blank"
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {selectedGarage?.urlLink
                                      ? selectedGarage?.urlLink
                                      : "-"}
                                  </a>
                                </td>
                                <td>
                                  <img
                                    style={{ height: 21 }}
                                    src={item?.logo}
                                    alt=""
                                  />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan="5"
                              style={{ textAlign: "center", color: "black" }}
                            >
                              No Social Media History.
                            </td>
                          </tr>
                        )}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{}}>
                            <button
                              style={{
                                backgroundColor: "#b11226",
                                margin: 5,
                                padding: 5,
                                color: "white",
                                border: "none",
                              }}
                              onClick={() => socialmedia(selectedGarage)}
                            >
                              Add Social Media
                            </button>
                          </div>
                        </div>
                      </tbody>
                    </table>
                  </div>
                </Modal>
              )}
            </div>

            <div>
              {modalXV4 && (
                <Modal
                  footer={null}
                  centered
                  visible={modalXV4}
                  onCancel={cancelx}
                >
                  <div>
                    <div className="add--manage-modal-content-header">
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "#b11226 " }}>
                          URL
                        </h3>
                      </div>
                    </div>

                    <div>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          margin: 10,
                        }}
                      >
                        <Col xl={24} lg={24}>
                          <a
                            href={selectedGarage?.urlLink}
                            target="_blank"
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              textAlign: "center",
                            }}
                          >
                            {selectedGarage?.urlLink
                              ? selectedGarage?.urlLink
                              : "-"}
                          </a>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
            <div>
              {modalXV9 && (
                <Modal
                  footer={null}
                  centered
                  visible={modalXV9}
                  onCancel={cancelx}
                >
                  <div>
                    <div className="add-subscription-manage-modal-content-header">
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "#b11226 " }}>
                          Update Coins
                        </h3>
                      </div>
                    </div>
                    <div>
                      <Row style={{ marginBottom: 10 }}>
                        <Col span={12}>
                          <h3>Previous Coins: {initialValue}</h3>
                        </Col>
                        <Col span={12}>
                          <h3 style={{ textAlign: "end" }}>
                            Updated Coins:{" "}
                            {operation === "+"
                              ? initialValue + result
                              : initialValue - result}
                          </h3>
                        </Col>
                      </Row>
                      <Row style={{ marginBottom: 10 }}>
                        <Col span={16}>
                          <Input
                            onChange={handleInputChange}
                            placeholder="Enter a number"
                          />
                        </Col>
                        <Col span={8}>
                          <Select
                            value={operation}
                            onChange={handleOperationChange}
                            style={{ width: "100%" }}
                          >
                            <Option value="+">Add</Option>
                            <Option value="-">Subtract</Option>
                          </Select>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: 20 }}>
                        <Col span={19} style={{ textAlign: "end" }}>
                          <Button type="danger" onClick={handleCalculateCancel}>
                            Cancel
                          </Button>
                        </Col>
                        <Col span={5} style={{ textAlign: "end" }}>
                          <Button type="primary" onClick={handleCalculate}>
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
            <div>
              {modalXV5 && (
                <Modal
                  footer={null}
                  centered
                  visible={modalXV5}
                  onCancel={cancelx}
                  width={"auto"}
                >
                  <div className="garage-manage-table">
                    <div className="add-subscription-manage-modal-content-header">
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                          Working Hours
                        </h3>
                      </div>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th className="th1">S.No</th>
                          <th className="th4">Day</th>
                          <th className="th4">Day Status</th>
                          <th className="th4">Start Time</th>
                          <th className="th5">End Time</th>
                        </tr>
                      </thead>

                      <tbody>
                        {selectedGarage?.workingHours?.length === 0 ? (
                          <tr>
                            <td colSpan="13" style={{ textAlign: "center" }}>
                              No Working Hours.
                            </td>
                          </tr>
                        ) : (
                          selectedGarage?.workingHours?.map((item, index) => {
                            // const words = item?.garageName?.split(" ");
                            console.log(
                              selectedGarage?.workingHours?.length === 0
                            );
                            return (
                              <tr key={item._id}>
                                <td>{startIndex + index + 1}</td>
                                <td>{item.Day || "-"}</td>
                                <td>{item.DayStatus}</td>
                                <td>{item.StartTime}</td>
                                <td>{item.EndTime}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </Modal>
              )}
            </div>

            <div>
              {modalXV && (
                <Modal
                  footer={null}
                  centered
                  visible={modalXV}
                  onCancel={cancelx}
                  width={"auto"}
                >
                  <div className="garage-manage-table">
                    <div className="add-subscription-manage-modal-content-header">
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                          Bank Details
                        </h3>
                      </div>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th className="th1">S.No</th>
                          <th className="th4">Holder Name</th>
                          <th className="th4">Bank Name</th>
                          <th className="th4">Branch</th>

                          <th className="th5">IFSC Code</th>
                          <th className="th5">AC Number</th>
                        </tr>
                      </thead>

                      <tbody>
                        {selectedGarage?.banks?.length === 0 ? (
                          <tr>
                            <td colSpan="13" style={{ textAlign: "center" }}>
                              No Bank Details History.
                            </td>
                          </tr>
                        ) : (
                          selectedGarage?.banks?.map((item, index) => {
                            // const words = item?.garageName?.split(" ");

                            const formattedDate1 = moment(
                              item?.startDate
                            ).format("DD-MM-YYYY");
                            const formattedDate2 = moment(item?.endDate).format(
                              "DD-MM-YYYY"
                            );

                            console.log(formattedDate2);
                            return (
                              <tr key={item._id}>
                                <td>{startIndex + index + 1}</td>
                                <td>{item.nameAsPerBankAccount}</td>
                                <td>{item.bankName}</td>
                                <td>{item.branch}</td>
                                <td>{item.ifscCode}</td>

                                <td>{item.accountNumber}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </Modal>
              )}
            </div>
            <div>
              {modalXV6 && (
                <Modal
                  footer={null}
                  centered
                  visible={modalXV6}
                  onCancel={cancelx}
                  width={"auto"}
                >
                  <div className="garage-manage-table">
                    <div className="add-subscription-manage-modal-content-header">
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                          Add Media
                        </h3>
                      </div>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          <th className="th4">Link</th>
                          <th className="th4">Logo</th>
                          <th className="th4">Name</th>

                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        {socialMediaList.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <Input
                                placeholder="Url"
                                type="text"
                                value={item.link}
                                onChange={(e) =>
                                  handleSocialMediaChange(
                                    index,
                                    "link",
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Select
                                placeholder="Select Media"
                                style={{ width: 200 }}
                                value={item.logo}
                                onChange={(value) =>
                                  handleSocialMediaChange(index, "logo", value)
                                }
                              >
                                {logoOptions.listOfSocialMedias?.map((logo) => (
                                  <Option key={logo?.id} value={logo?.logo}>
                                    {logo?.socialMediaName}
                                  </Option>
                                ))}
                              </Select>
                            </td>
                            <td>
                              <Select
                                placeholder="Select Media"
                                style={{ width: 200 }}
                                value={item.socialMediaName}
                                onChange={(value) =>
                                  handleSocialMediaChange1(
                                    index,
                                    "socialMediaName",
                                    value
                                  )
                                }
                              >
                                {logoOptions.listOfSocialMedias?.map((logo) => (
                                  <Option
                                    key={logo?.id}
                                    value={logo?.socialMediaName}
                                  >
                                    {logo?.socialMediaName}
                                  </Option>
                                ))}
                              </Select>
                            </td>
                            <td>
                              <Button
                                style={{ marginTop: 10 }}
                                type="primary"
                                danger
                                onClick={() => handleRemoveSocialMedia(index)}
                              >
                                <MinusCircleOutlined />
                              </Button>
                              <Button
                                style={{ marginTop: 10 }}
                                type="primary"
                                onClick={handleAddSocialMedia}
                              >
                                <PlusOutlined />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <Button
                      style={{ margin: 10 }}
                      type="primary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </div>
                </Modal>
              )}
            </div>
            <div>
              {modalXV7 && (
                <Modal
                  footer={null}
                  centered
                  visible={modalXV7}
                  onCancel={cancelx}
                  width={1200}
                >
                  <div>
                    <div className="add-subscription-manage-modal-content-header">
                      <div>
                        <h3 style={{ fontWeight: "bold", color: "#b11226" }}>
                          Images
                        </h3>
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: 10, margin: 10 }}>
                      <div style={{ width: "100%" }}>
                        <MultipleImageUpload
                          selectedGarage={selectedGarage}
                          handleCloseModal={cancelx}
                          fetchGarageList={fetchGarageList}
                          setImagesPreview={setImages}
                        />
                      </div>
                      {/* <div>
                        {image ? (
                          <div
                            style={{
                              border: "1px solid black",
                              height: 300,
                              width: 250,
                            }}
                          >
                            <img
                              // key={}
                              src={image}
                              style={{ height: 500, width: 250 }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              border: "1px solid black",
                              height: 300,
                              width: 250,
                            }}
                          >
                            <span style={{ padding: 10 }}>
                              No Image Preview
                            </span>
                          </div>
                        )}
                      </div> */}
                    </div>
                  </div>
                </Modal>
              )}
            </div>

            <div>
              {modalXV1 && (
                <ViewServices
                  handleCloseModal={handleCloseModal}
                  open={modalXV1}
                  selectedGarage={selectedGarage}
                  fetchGarageList={fetchGarageList} // Pass the selected garage details as props
                  load={load}
                />
              )}
            </div>

            <div>
              {modalXV10 && (
                <Modal
                  footer={null}
                  centered
                  width={1200}
                  visible={modalXV10}
                  onCancel={cancelx}
                  style={{ top: 20 }} 
                  bodyStyle={{
                    height: "37rem",
                    overflowY: "auto", 
                    padding: "0rem", 
                  }}
                >
                  <TabsComponent garageId={garageIdData} />
                </Modal>
              )}
            </div>

            <div>
              {isModalVisible && (
                <EditCategory
                  handleCloseModal={handleCloseModal}
                  open={isModalVisible}
                  selectedGarage={selectedGarage}
                  fetchGarageList={fetchGarageList} // Pass the selected garage details as props
                />
              )}
            </div>
          </div>
        )}
        <Pagination
          simple
          onChange={handlePageChange}
          total={filteredGarageList.length}
          current={currentPage}
          style={{ marginTop: 10, float: "right" }}
        />
      </Spin>
    </>
  );
};

export default CategoryTable;
