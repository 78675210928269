import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Radio,
  DatePicker,
  Button,
  Row,
  Col,
  Typography,
  message,
  Modal,
} from "antd";
import LocationSearch from "../GarageManagement/Location";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import moment from "moment";

const { Title } = Typography;
const { Option } = Select;

const GeneratePaymentLinkForm = ({ garageId }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [lat, setLatitude] = useState("");
  const [long, setLongitude] = useState("");
  const [addressed, setAddressed] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paymentLink, setPaymentLink] = useState("");

  const token = localStorage.getItem("admin");

  // Fetch garage data
  const fetchGarageData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${ApiDomain.Domain}/getSingleGarage`,
        { garageId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data?.data) {
        const garageData = response.data.data;

        form.setFieldsValue({
          garageName: garageData.garageName,
          contactNo: garageData.phoneNumber,
          address: garageData.address,
          state: garageData.mapState,
          panNumber: garageData.pancardNumber,
          gstNumber: garageData.gstNumber,
          ownerName: garageData.garageOwnerName,
          email: garageData.email,
          area: garageData.area,
          city: garageData.mapCity,
          businessType: garageData.businessType,
          vehicleType: "Both",
          timePeriod: "",
        });

        setAddressed(garageData.address);
        setLatitude(garageData.location?.coordinates[1]);
        setLongitude(garageData.location?.coordinates[0]);
      } else {
        message.error("Failed to fetch garage data.");
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred while fetching garage data.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch states
  const fetchStates = async () => {
    try {
      const response = await axios.get(`${ApiDomain.Domain}/listOfStates`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStates(response?.data?.data || []);
    } catch (error) {
      console.error(error.message);
      message.error("Failed to fetch states.");
    }
  };

  // Fetch cities based on state
  const fetchCities = async (stateId) => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/listOfCities/${stateId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setCities(response?.data?.data || []);
    } catch (error) {
      console.error(error.message);
      message.error("Failed to fetch cities.");
    }
  };

  useEffect(() => {
    fetchGarageData();
    fetchStates();
  }, []);

  const onFinish = async (values) => {
    const formattedExpiryDate = moment(values.expiryDate).format("YYYY-MM-DD");

    const payload = {
      name: values.garageName,
      phone: values.contactNo,
      address: addressed,
      state: values.state,
      PANNumber: values.panNumber,
      GSTNumber: values.GSTNumber,
      totalAmount: values.totalAmount,
      expiryDate: formattedExpiryDate,
      ownerName: values.ownerName,
      email: values.email,
      area: values.area,
      city: values.city,
      businessType: values.businessType,
      vehicleType: values.vehicleType.toString(),
      timePeriod: values.timePeriod,
      garageId,
      paymentFor: "plan", //plan or feature
      plan: "launch", //launch , rise or scale
      feature: [],
    };

    console.log("Payload:", payload);

    try {
      setLoading(true);
      const response = await axios.post(
        `${ApiDomain.Domain}/subscription/generatePaymentLink`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response?.data?.responseCode === 200) {
        message.success("Payment link generated successfully!");
        console.log("Payment Link:", response.data.data.paymentLink);
        setPaymentLink(response.data.data.paymentLink);
        setIsModalVisible(true);
      } else {
        message.error(
          response?.data?.message || "Failed to generate payment link."
        );
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred while generating the payment link.");
    } finally {
      setLoading(false);
    }
  };

  const handleSend = () => {
    message.info("Please connect with V2R Team");
    setIsModalVisible(false);
  };

  return (
    <div
      style={{
        padding: "1rem",
        margin: "0 auto",
        backgroundColor: "#FFFFFF",
        maxWidth: "1000px",
      }}
    >
      <Title level={3} style={{ textAlign: "center", color: "#B11226" }}>
        Generate Payment Link
      </Title>

      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{ vehicleType: "Both" }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Garage Name:" name="garageName">
              <Input placeholder="Garage Name" disabled />
            </Form.Item>

            <Form.Item
              label="Contact No:"
              name="contactNo"
              rules={[
                { required: true, message: "Please enter contact number." },
              ]}
            >
              <Input placeholder="Enter Mobile Number" />
            </Form.Item>

            <LocationSearch
              address={addressed}
              updateValue={setAddressed}
              setLatitude={setLatitude}
              setLongitude={setLongitude}
            />

            <Form.Item
              label="State:"
              name="state"
              rules={[{ required: true, message: "Please select a state." }]}
            >
              <Select
                placeholder="Select State"
                onChange={(value) => fetchCities(value)}
              >
                {states.map((state) => (
                  <Option key={state.id} value={state.id}>
                    {state.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="PAN Number:" name="panNumber">
              <Input placeholder="Enter PAN Card Number" />
            </Form.Item>

            <Form.Item label="GST Number:" name="GSTNumber">
              <Input placeholder="Enter GST Number" />
            </Form.Item>

            <Form.Item
              label="Total Amount:"
              name="totalAmount"
              rules={[
                { required: true, message: "Please enter total amount." },
              ]}
            >
              <Input placeholder="Enter the amount" />
            </Form.Item>

            <Form.Item
              label="Expiry Date:"
              name="expiryDate"
              rules={[
                { required: true, message: "Please select expiry date." },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Owner Name:" name="ownerName">
              <Input placeholder="Owner Name" />
            </Form.Item>

            <Form.Item label="Email:" name="email">
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              label="Area:"
              name="area"
              rules={[{ required: true, message: "Please enter the area." }]}
            >
              <Input placeholder="Enter Area" />
            </Form.Item>

            <Form.Item label="City:" name="city">
              <Select placeholder="Select City">
                {cities.map((city) => (
                  <Option key={city.id} value={city.id}>
                    {city.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Business Type:"
              name="businessType"
              rules={[
                { required: true, message: "Please select business type." },
              ]}
            >
              <Select placeholder="Select Business Type">
                <Option value="workshop">Workshop</Option>
                <Option value="accessories">Accessories</Option>
                <Option value="services">Services</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Vehicle Type:"
              name="vehicleType"
              rules={[
                { required: true, message: "Please select vehicle type." },
              ]}
            >
              <Radio.Group>
                <Radio value={1}>4W</Radio>
                <Radio value={2}>2W</Radio>
                <Radio value={3}>Both</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item label="Time Period:" name="timePeriod">
              <Select placeholder="Select the time period">
                <Option value={1}>1 Month</Option>
                <Option value={3}>3 Months</Option>
                <Option value={9}>9 Months</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ textAlign: "right", marginTop: "1rem" }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Generate
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title=""
        visible={isModalVisible}
        width={350}
        centered
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <p>Your payment link has been generated successfully.</p>
        <Input value={paymentLink} readOnly />
        <div style={{ marginTop: "1rem", textAlign: "center" }}>
          <Button
            style={{ backgroundColor: "#b11336", color: "white" }}
            onClick={handleSend}
          >
            Send
          </Button>
        </div>
      </Modal>

      
    </div>
  );
};

export default GeneratePaymentLinkForm;
