import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import ApiDomain from "../../../api/ApiDomain";
import { Pagination } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import XLSTrackingTable from "./XLSTrackingTable";

const XLSTracking = () => {
  const [trackingData, setTrackingData] = useState([]); // Updated state to hold tracking data
  const [load, setLoad] = useState(false);
  const itemsPerPage = 10; // Number of items per page
  const [page, setPage] = useState(1); // Current page
  const [count, setCount] = useState(0); // Total count of tracking records
  const token = localStorage.getItem("admin");

  const fetchstart = () => {
    setLoad(true);
  };

  const fetchsuccess = () => {
    setLoad(false);
  };

  const fetchTrackingData = async () => {
    try {
      fetchstart();
      const response = await axios.post(
        `${ApiDomain.Domain}/xlsTracking`, // Endpoint to fetch tracking data
        {
          page: page, // Current page
          limit: itemsPerPage, // Limit per page
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        setTrackingData(response.data.data.responseData); // Set tracking data
        setCount(response.data.totalCount); // Set total count
        fetchsuccess();
      }
    } catch (error) {
      console.log(error.message);
      fetchsuccess();
    }
  };

  useEffect(() => {
    fetchTrackingData(); // Fetch tracking data on component mount and page change
  }, [page]); // Add 'page' as a dependency

  const handlePageChange = (page) => {
    setPage(page); // Update the current page
  };

  return (
    <div className="vendor-manage-container">
      <div className="vendor-manage-content">
        <div className="vendor-manage-header">
          <h3 className="vendor-manage-head">XLS Tracking</h3>
          <ReloadOutlined
            style={{
              marginLeft: "10px",
              border: "1px solid black",
              cursor: "pointer",
              color: "black",
              padding: 7,
              borderRadius: 5,
            }}
            onClick={fetchTrackingData} // Reload tracking data
          />
        </div>
        <div className="vendor-manage-card">
          <XLSTrackingTable load={load} data={trackingData} />
        </div>
      </div>
    </div>
  );
};

export default XLSTracking;
