import React, { useState, useEffect } from "react";
import {
  CloudUploadOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import {
  message,
  Upload,
  Form,
  Col,
  Row,
  Button,
  Input,
  TimePicker,
  Checkbox,
  Radio,
  AutoComplete,
  Spin,
  Modal,
  Select,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import "./Pushnotificarion.css";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import ApiDomain from "../../../api/ApiDomain";

const { Option } = Select;

const AddPushnotification = ({
  handleCloseModal,
  fetchGarageList,
  isModalVisible,
}) => {
  const [form] = Form.useForm();
  const [loadingCar, setLoadingCar] = useState(false);
  const [imageUrlCar, setImageUrlCar] = useState();
  const [garageList, setGarageList] = useState([]);
  const [load, setLoad] = useState(false);
  const [autoSuggestions, setAutoSuggestions] = useState([]);
  const [source, setSource] = useState([]);
  const [location, setLocation] = useState([]);
  const [workingHours, setWorkingHours] = useState([
    { day: "Monday", slots: [], disabled: false },
    { day: "Tuesday", slots: [], disabled: false },
    { day: "Wednesday", slots: [], disabled: false },
    { day: "Thursday", slots: [], disabled: false },
    { day: "Friday", slots: [], disabled: false },
    { day: "Saturday", slots: [], disabled: false },
    { day: "Sunday", slots: [], disabled: false },
  ]);
  const history = useHistory();
  const [isGSTExist, setIsGSTExist] = useState(false);
  const [slot, setSlots] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const [selectedLocations, setSelectedLocations] = useState(""); // Changed to an array
  const [selectedState, setSelectedState] = useState(""); // Changed to an array

  const [selectedBusiness, setselectedBusinessType] = useState("");
  const [filteredGarageList, setFilteredGarageList] = useState([]);
  const [name, setName] = useState("");
  const [state, setState] = useState([]);
  const handleChangeCar = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      setLoadingCar(true);
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        "https://api.vecrep.com/uploadImage",
        formData
      );
      const imageUrl = response.data.imagePath1;
      setImageUrlCar(imageUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoadingCar(false);
    }
  };

  const handleDayToggle = (day) => {
    setWorkingHours((prev) =>
      prev.map((item) =>
        item.day === day ? { ...item, disabled: !item.disabled } : item
      )
    );
  };

  const handleInputChange = (value) => {
    const suggestions = garageList.filter(
      (garage) =>
        typeof garage === "string" &&
        garage.toLowerCase().includes(value.toLowerCase())
    );
    setAutoSuggestions(suggestions);
  };

  const token = localStorage.getItem("admin");

  // const fetchLocationList = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${ApiDomain.Domain}/getGarageCities`,
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     );
  //     setLocation(response?.data?.data);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  const fetchGarageLists = async () => {
    try {
      setLoad(true);
      const response = await axios.get(`${ApiDomain.Domain}/garageNameList`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setSource(response.data.data);
        setLoad(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchGarageLists();
  }, []);

  const handleSubmit = async (values) => {
    console.log(values);
    setLoad(true);
    try {
      const response = await fetch(`${ApiDomain.Domain}/addPushNotification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...values,
          state: name,
          image: imageUrlCar,
        }),
      });
      console.log(response);
      if (response.status === 201) {
        fetchGarageList();
        message.success("Notification added successfully");
        handleCloseModal();
      } else if (response.status === 200) {
        console.error("API call failed");
        message.error("No garages found for the specified criteria");
      } else if (response.status === 205) {
        message.error("No device tokens found for the specified criteria");
      }
    } catch (error) {
      console.error("An error occurred during the API call:", error);
      message.error("Error Notification add");
    } finally {
      setLoad(false);
    }
  };

  const handleCancel = () => {
    handleCloseModal();
  };

  const handleVehicleChange = (value) => {
    setSelectedVehicleType(value);
    filterGarageSuggestions();
  };

  const handleLocationChange = (value) => {
    console.log(value);
    setSelectedLocations(value); // Update state with selected locations
    filterGarageSuggestions();
  };

  const handleBusinessChange = (value) => {
    setselectedBusinessType(value);
    filterGarageSuggestions();
  };

  useEffect(() => {
    filterGarageSuggestions();
  }, [selectedBusiness, selectedLocations, selectedVehicleType, selectedState]);

  const filterGarageSuggestions = () => {
    let filteredList = source;

    // Always filter by selected state first
    if (selectedState != "") {
      console.log(selectedState);
      filteredList = filteredList.filter(
        (garage) => garage.state == selectedState
      );
      setFilteredGarageList(filteredList);
    }

    if (selectedLocations != "" && selectedLocations.length > 0) {
      filteredList = filteredList.filter((garage) =>
        selectedLocations.includes(garage.city)
      );
      setFilteredGarageList(filteredList);
    }

    // Further filter by selected vehicle type if any

    if (selectedVehicleType != "") {
      console.log(selectedVehicleType);
      filteredList = filteredList.filter(
        (garage) => garage.garageType == selectedVehicleType
      );
      setFilteredGarageList(filteredList);
    }

    // // Further filter by selected business type if any
    if (selectedBusiness != "") {
      filteredList = filteredList.filter(
        (garage) => garage.businessType == selectedBusiness
      );
      setFilteredGarageList(filteredList);
    }
    setFilteredGarageList(filteredList);
  };
  console.log(filteredGarageList);

  const fetchServiceStates = async () => {
    try {
      const response = await axios.get(`${ApiDomain.Domain}/listOfStates`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response.data.data);
      setState(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const fetchCities = async (ids) => {
    try {
      const response = await axios.get(
        `${ApiDomain.Domain}/listOfCities/${ids}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data.data);
      setLocation(response?.data?.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchServiceStates();
  }, []);
  const handleStateChange = (value) => {
    const split_id = value.split(",");
    setName(split_id[1]);
    fetchCities(split_id[0]);
    setSelectedState(split_id[1]); // Update state with selected locations
    filterGarageSuggestions();
  };

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width={650}
      >
        <div className="">
          <div className="add-garage-manage-modal-content-header">
            <div>
              <h3
                style={{
                  fontWeight: "bold",
                  color: "#b11226",
                  textAlign: "center",
                }}
              >
                Add PushNotification
              </h3>
            </div>
          </div>
          <div
            className="add-garage-manage-modal-forms"
            style={{ maxHeight: "390px", overflowY: "scroll", width: 600 }}
          >
            <Spin spinning={load}>
              <div className="">
                <Form
                  form={form}
                  layout="vertical"
                  style={{ minWidth: "500px" }}
                  onFinish={handleSubmit}
                >
                  <div>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <Col xl={24} lg={24}>
                          <Form.Item
                            label="Image : "
                            rules={[
                              {
                                // required: true,
                                message: "Please upload Image",
                              },
                            ]}
                          >
                            <div
                              name="categoryImage"
                              listType="picture-card"
                              className="avatar-uploader"
                            >
                              {imageUrlCar ? (
                                <div style={{ display: "flex", gap: 10 }}>
                                  <img
                                    src={imageUrlCar}
                                    alt="bike-avatar"
                                    style={{ width: "20%" }}
                                  />
                                  <div class="upload">
                                    <button type="button" class="btn-warning">
                                      <CloudUploadOutlined /> Replace File
                                      <input
                                        type="file"
                                        onChange={handleChangeCar}
                                      />
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div class="upload">
                                    <button type="button" class="btn-warning">
                                      <CloudUploadOutlined /> Upload File
                                      <input
                                        type="file"
                                        onChange={handleChangeCar}
                                      />
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </Form.Item>
                        </Col>
                      </div>

                      <div style={{ width: "100%" }}>
                        <Col xl={24} lg={24}>
                          <Form.Item name="state" label="State">
                            <Select
                              showSearch
                              className="custom-select-dropdown ant-select-selection-overflow "
                              placeholder="Select State "
                              optionFilterProp="children"
                              onChange={handleStateChange} // Update onChange handler
                              filterOption={(input, option) =>
                                option?.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {state?.map((suggestion) => (
                                <Option
                                  key={suggestion._id}
                                  value={`${suggestion._id},${suggestion.stateName}`}
                                >
                                  {suggestion.stateName}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </div>

                      <div style={{ width: "100%" }}>
                        <Col xl={24} lg={24}>
                          <Form.Item name="location" label="City">
                            <Select
                              mode="multiple" // Enable multiple selection
                              showSearch
                              className="custom-select-dropdown ant-select-selection-overflow "
                              placeholder="Select City"
                              optionFilterProp="children"
                              onChange={handleLocationChange} // Update onChange handler
                              filterOption={(input, option) =>
                                option?.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {location?.map((suggestion) => (
                                <Option
                                  key={suggestion.cityName}
                                  value={suggestion.cityName}
                                >
                                  {suggestion.cityName}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </div>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <div className="select-extra">
                          <Col xl={24} lg={24}>
                            <Form.Item name="vehicleType" label="Vehicle Type">
                              <Select
                                showSearch
                                style={{ height: 40 }}
                                placeholder="Select Vehicle Type"
                                optionFilterProp="children"
                                onChange={handleVehicleChange}
                                filterOption={(input, option) =>
                                  option?.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="1">Car</Option>
                                <Option value="2">Bike</Option>
                                <Option value="3">Both</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </div>
                      </div>
                      <div style={{ width: "50%" }}>
                        <Col xl={24} lg={24}>
                          <Form.Item name="businessType" label="Business Type">
                            <Select
                              showSearch
                              style={{ height: 40 }}
                              placeholder="Select Business Type"
                              optionFilterProp="children"
                              onChange={handleBusinessChange}
                              filterOption={(input, option) =>
                                option?.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Option value="Workshops">
                                Workshops/Garages/Modification
                              </Option>
                              <Option value="Accessories">Accessories</Option>
                              <Option value="Washing">Washing/Detailing</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </div>
                      <div style={{ width: "100%" }}>
                        <Col xl={24} lg={24}>
                          <Form.Item name="garageId" label="Garages">
                            <Select
                              mode="multiple"
                              showSearch
                              style={{ height: 40 }}
                              placeholder="Select Garage"
                              optionFilterProp="children"
                              className="custom-select-dropdown ant-select-selection-overflow "
                              filterOption={(input, option) =>
                                option?.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {filteredGarageList.map((item) => (
                                <Option key={item._id} value={item._id}>
                                  {item.garageName}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </div>
                    </Row>
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Col xl={24} lg={24}>
                          <Form.Item name="title" label="Notification Title">
                            <Input placeholder="Notification Title" />
                          </Form.Item>
                        </Col>
                      </div>
                      <Col xl={24} lg={24}>
                        <Form.Item
                          name="content"
                          label="Notification Description"
                        >
                          <TextArea placeholder="Notification Description" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <div className="add-garage-manage-popup-footer">
                    <button
                      className="cancel-button"
                      onClick={handleCancel}
                      style={{ marginRight: 8 }}
                    >
                      Cancel
                    </button>
                    <button className="submit-button" htmlType="submit">
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </Spin>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddPushnotification;
